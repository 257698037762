@media (max-width: 1199px) {
  .calc-comment, .valuta-box.valuta-box-info label {
    font-size: 9px;
    letter-spacing: -.3px;
  }
  .post h2 {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  .post-img{
    height: 250px;
    background-size: cover;
  }
}
@media (max-width: 768px) {
  .hidden-for-mobile{
    display: none;
  }

  h1{
    margin-top:10px;
    margin-left: 10px;
  }
  .calculator .valuta-main{
    height: 400px;
  }
  .logo{
    width: 90px;
    height: 50px;
  }
  .phonenumber{
    text-align: center;
    font-size: 14px;
    margin-top: 25px;
  }
  .mobile-menu{
    display: block;
    float: right;
    font-size: 22px;
    margin-top: -45px;
    margin-right: 10px;
  }
  .mobile-menu a{
    color: #d5261c;
    display: block;
    float: none;
    text-align: right;
  }

  .valuta-main{
    width: 100%;
    margin-top: 15px;
  }
  .main-text.row{
    padding: 0px 15px;
  }
  .posts{
   width: 100%;
  }
  .post-text{
    padding: 0px 10px;
  }
  footer.container{
    padding: 0px;
  }
  .girl{
    margin-top: -100px;
  }
  footer .address{
    text-align: center;
    margin-top: 0px;
  }
  nav.main{
    position: absolute;
    right: 10px;
    top: 45px;
    z-index: 99;
    padding: 15px;
    background-color: white;
    border: 1px solid black;
    line-height: 170%;
    display: none;
  }
  .more-valuta{
    display: block;
    padding: 5px;
    background-color: #d5261c;
    width: 100%;
    text-align: center;
    color: white;
    margin-top: 10px;
  }
}