$color-primary: #d5261c;
$color-primary-lighten-1: #de544c;
$color-secondary: #1d1d1b;
$color-secondary-lighten-1: #545454;
$color-secondary-lighten-2: #c6c6c6;
$color-secondary-lighten-3: #e3e3e3;


@font-face {
  font-family: "gotham_thin";
  src: url("../fonts/gotham-thin.eot");
  src: url("../fonts/gotham-thin.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-thin.woff") format("woff"), url("../fonts/gotham-thin.ttf") format("truetype"), url("../fonts/gotham-thin.svg#gothamthin") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "gotham_bold";
  src: url("../fonts/gotham-bold.eot");
  src: url("../fonts/gotham-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-bold.woff") format("woff"), url("../fonts/gotham-bold.ttf") format("truetype"), url("../fonts/gotham-bold.svg#GothamBold") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "gotham_black";
  src: url("../fonts/gotham-black.eot");
  src: url("../fonts/gotham-black.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-black.woff") format("woff"), url("../fonts/gotham-black.ttf") format("truetype"), url("../fonts/gotham-black.svg#gothamblack") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "gotham_book";
  src: url("../fonts/gotham-book.eot");
  src: url("../fonts/gotham-book.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-book.woff") format("woff"), url("../fonts/gotham-book.ttf") format("truetype"), url("../fonts/gotham-book.svg#gothambook") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "gotham_medium";
  src: url("../fonts/gotham-medium.eot");
  src: url("../fonts/gotham-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-medium.woff") format("woff"), url("../fonts/gotham-medium.ttf") format("truetype"), url("../fonts/gotham-medium.svg#gothammedium") format("svg");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: "gotham_light";
  src: url("../fonts/gotham-light.eot");
  src: url("../fonts/gotham-light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham-light.woff") format("woff"), url("../fonts/gotham-light.ttf") format("truetype"), url("../fonts/gotham-light.svg#gothamlight") format("svg");
  font-weight: normal;
  font-style: normal; }
@-webkit-keyframes pulse {
  0%   { background-color: $color-primary; }
  50% { background-color: black; }
  100% { background-color: $color-primary; }
}
@-moz-keyframes pulse {
  0%   { background-color: $color-primary; }
  50% { background-color: black; }
  100% { background-color: $color-primary; }
}
@-o-keyframes pulse {
  0%   { background-color: $color-primary; }
  50% { background-color: black; }
  100% { background-color: $color-primary; }
}
@keyframes pulse {
  0%   { background-color: $color-primary; }
  50% { background-color: black; }
  100% { background-color: $color-primary; }
}

@-webkit-keyframes pulserev {
  0%   { background-color: black; }
  50% { background-color: $color-primary; }
  100% { background-color: black; }
}
@-moz-keyframes pulserev {
  0%   { background-color: black; }
  50% { background-color: $color-primary; }
  100% { background-color: black; }
}
@-o-keyframes pulserev {
  0%   { background-color: black; }
  50% { background-color: $color-primary; }
  100% { background-color: black; }
}
@keyframes pulserev {
  0%   { background-color: black; }
  50% { background-color: $color-primary; }
  100% { background-color: black; }
}

.animate {
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.hidden-for-desktop{
  display: none;
}

html, body {
  font-family: 'gotham_book', Arial;
  background-color: #fff;
  color: $color-secondary-lighten-1;
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
small{
  font-size: 10px;
}
table tr th {
  background: white;
  font-size: 10px;
  font-family: 'gotham_medium', Arial;
  padding-bottom: 15px;
}
table tr td{
  padding: 5px;
}
table tr:nth-child(odd) {
  background-color: #eee;
}
h1{
  font-family: 'gotham_medium', Arial;
  font-style: italic;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 10px;
}
.roboto{
  font-family: 'Roboto Mono', Arial;
  font-weight: 400;
}
header{
  margin-bottom: 33px;
}
.info-line{
  font-family: 'gotham_medium', Arial;
  font-style: italic;
  font-size: 20px;
  color: $color-primary;
}
.logo{
  width: 184px;
  height: 102px;
  margin-top: 20px;
  background-image: url(../img/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.logo-footer{
  width: 115px;
  height: 64px;
  margin-top: 20px;
  background-image: url(../img/logo-footer.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.valuta-main{
  width: calc(100% - 15px);
  background-color: $color-primary;
  color: white;
  height: 130px;
  float: right;
}
.valuta-main h2{
  font-family: 'gotham_medium', Arial;
  font-style: italic;
  font-size: 13px;
  margin: 0;
  padding: 8px 15px;
  text-transform: uppercase;
  background-color: $color-secondary;
  color: white;
  height: 30px;
}
.valuta-main .amount{
  font-size: 24px;
}
.valuta-main .black-text{
  text-transform: uppercase;
  float: left;
  font-family: 'gotham_bold', Arial;
  font-style: italic;
  font-size: 13px;
  color: black;
  margin-top: 8px;
}
.valuta-main .currency{
  font-family: 'gotham_bold', Arial;
  font-weight: 700;
  font-size: 11px;
}
.buy-sell{
  height: 50px;
  padding: 8px 15px;
  border-bottom: 1px solid $color-primary-lighten-1;
}
.row-alert {
  color: white;
  font-family: 'gotham_bold', Arial;
  font-size: 14px;
  margin: 10px 0;
}
.row-alert .row-alert-right{
  padding: 5px 15px;
  background-color: black;
  text-align: center;
  -webkit-animation: pulserev 3s infinite; /* Safari 4+ */
  -moz-animation:    pulserev 3s infinite; /* Fx 5+ */
  -o-animation:      pulserev 3s infinite; /* Opera 12+ */
  animation:         pulserev 3s infinite;
}
.row-alert .row-alert-left{
  padding: 5px 15px;
  background-color: $color-primary;
  text-align: center;
  -webkit-animation: pulse 3s infinite; /* Safari 4+ */
  -moz-animation:    pulse 3s infinite; /* Fx 5+ */
  -o-animation:      pulse 3s infinite; /* Opera 12+ */
  animation:         pulse 3s infinite;
}
a{
  color: $color-primary;
  text-decoration: none;
}
nav {
  width: 100%;
  display: block;
}
nav.secondary{
  margin-top: 10px;
  padding-right: 60px;
}
nav.main{
  margin-top: 35px;
}
nav a, nav.secondary a{
  font-family: 'gotham_medium', Arial;
  font-size: 13px;
  color: $color-secondary;
  text-transform: uppercase;
  float:right;
  margin-left: 44px;
}
nav.secondary a{
  font-size: 10px;
  color: $color-secondary-lighten-2;
  margin-left: 30px;
}
nav.secondary a.flag-link{
  margin-left: 10px;
  width: 19px;
  height: 13px;
  background-position: center;
}
nav a:hover, nav.secondary a:hover{
  color: $color-primary;
  text-decoration: none;
}
.phonenumber{
  font-family: 'gotham_medium', Arial;
  width: 100%;
  text-align: right;
  font-size: 22px;
  color: $color-secondary;
  padding-right: 60px;
}
.tabak-icon{
  background: url(../img/tabak-icon.png) no-repeat;
  width: 46px;
  height: 57px;
  float: right;
}
.map{
  width: calc(100% - 10px);
  height: 325px;
  margin-bottom: 30px;
}
a.place-button{
  line-height: 1.1;
  letter-spacing: -0.7px;
  color: $color-secondary;
  width: 89px;
  height: 40px;
  padding:5px 5px 5px 8px;
  float: left;
  margin-right: 10px;
  background:  $color-secondary-lighten-3;
  border-bottom: 2px solid $color-secondary-lighten-2;
}
a.place-button:hover{
  text-decoration: none;
  background: $color-primary;
  color: white;
  cursor: pointer;
}
a.all-place-button{
  font-family: 'gotham_medium', Arial;
  font-style: italic;
  text-transform: uppercase;
  width: 180px;
  padding:14px;
  float: right;
  text-align: center;
}
.valuta-box{
  width: 89px;
  float: left;
  margin-right: 10px;
}
.valuta-box label{
  font-size: 15px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: $color-secondary-lighten-2;
}
.valuta-box a {
  font-size: 18px;
  width: 100%;
  padding:8px;
  display: block;
  text-align: center;
  background:  $color-secondary-lighten-3;
  color: $color-secondary;
}
.valuta-box a:hover {
  text-decoration: none;
  color: white;
  background-color: $color-secondary;
}
.valuta-box.promoted a {
  color: white;
  background-color: $color-primary;
}
.valuta-box.promoted a:hover {
  color: white;
  background-color: $color-secondary;
}
.valuta-box.promoted label{
  color: $color-primary-lighten-1;
}

.valuta-box hr{
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 2px solid $color-secondary-lighten-2;
}
.valuta-box.promoted hr{
  border-bottom: 2px solid $color-primary-lighten-1;
}
.valuta-box.valuta-box-info label{
  font-size: 12px;
}
.valuta-box.valuta-box-info span{
  font-family: 'gotham_bold', Arial;
  font-style: italic;
  width: 100%;
  padding:8px 25px 3px 0px;
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  background: none;
  display: block;
}
.main-text{
  margin-bottom: 30px;
}


.valuta-row.promoted {
  background: rgba(213,38,28,.2);
}
/*CALC*/
.calculator{
  margin-top: 187px;
}
.calculator .valuta-main{
  height: 445px;
}
.calc-content{
  padding: 15px;
}
.girl{
  position: absolute;
  z-index: 9;
  width: 309px;
  height: 183px;
  background: url(../img/girl.png) no-repeat center;
  margin-left: -20px;
  margin-top: -120px;
}
.calc-input, .calc-result{
  width: 100%;
  height: 70px;
}
.calc-result{
  height: auto;
}
.calc-result span{
  color: $color-secondary;
  text-transform: uppercase;
  font-style: italic;
}
.result-buy-sell{
  font-size: 25px;
  color: white;
  margin-bottom: 10px;
}
.result-buy-sell span{
  font-style: normal;
  color: white;
  margin-left: -10px;
}
.calc-info{
  font-family: 'gotham_medium', Arial;
  font-size: 13px;
  text-transform: uppercase;
}
.calc-info span{
  font-family: 'gotham_medium', Arial;
  font-size: 26px;
}
.calc-comment{
  font-size: 10px;
  line-height: 12px;
}

.calc-amount{
  color: $color-secondary;
  border: 1px solid $color-secondary;
  font-family: 'Roboto Mono', Arial;
  font-size: 32px;
  padding: 5px 10px;
  text-align: right;
  float: left;
  width: 68%;
  position: relative;
}
.calc-currencies{
  margin-top: -6px;
  width: 30%;
  float: right;
}
.calc-currency{
  opacity: .5;
  display: block;
  margin: 0;
  padding: 0;
  line-height: .9;
  font-family: 'gotham_bold', Arial;
  font-size: 24px;
}
.calc-currency.slick-center{
  opacity: 1;
}

.slick-next{
  background: none;
  border: none;
}
.slick-next i{
  font-size: 20px;
  margin-left: 10px;
}

.slick-main-next{
  position: absolute;
  top: 57px;
  left: 785px;
  z-index: 10;
}
.posts{
  width: calc(100% - 10px);
}
.post{
  position: relative;
  width:100%;
  height: 230px;
  overflow: hidden;
}
.post h2{
  font-family: 'gotham_medium', Arial;
  font-style: italic;
  font-size: 13px;
  margin: 0;
  padding: 8px 15px;
  text-transform: uppercase;
  background-color: $color-secondary;
  color: white;
  height: 30px;
}
.post-img{
  margin-bottom: 10px;
  width: 100%;
  height: 130px;
  background-color: $color-secondary-lighten-3;
  background-position: center;
}
.post-readmore{
  display: block;
  font-size: 22px;
  color: $color-primary;
  background: white;
  padding: 3px 10px;
  position: absolute;
  top: 130px;
  right: 0px;
}
.post-readmore:hover{
  color: $color-secondary;
}

footer{
  margin-top: 30px;
  color: white;
  padding: 0px 60px;
}
footer .footer {
  border-top: 4px solid $color-primary;
  background: $color-secondary;
  padding: 20px 35px;
}
footer a{
  color: white;
  display: block;
  text-transform: uppercase;
  font-size: 15px;
}
footer a:hover{
  color: $color-primary;
  text-decoration: none;
}
footer .address{
  margin-top: 30px;
}

/*
Bootstrap Override
*/
div[class^="col"]{
  padding-left:10px;
  padding-right:10px;
}
.no-gutter div[class^="col"] {
  padding-left:0;
  padding-right:0;
}
.panel-default .panel-heading{
  border-radius: 0px;
  border: none;
  background: #eee;
}
.panel-default,
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group
{
  border: none;
}
.panel-body{
  padding-top:5px;
  height: 315px;
}

.panel-body .image {
  width: 100%;
  height: 300px;
  background: #eee;
  position: relative;
  background-position: center;
  background-size: cover;
}
.panel-body .image .info-panel{
  width: 100%;
  background: rgba(255,255,255,.8);
  bottom: 0;
  position: absolute;
  margin-left: 0px;
}
.panel-body .image .info-panel div{
  padding: 15px;
}
.panel-body .map {
  width: 100%;
  height: 300px;
  background: url(../img/loading-map.jpg);
}
.form-control{
  background: #eee;
  border: none;
  border-radius: 0;
}
textarea{
  height: 120px;
  min-height: 120px;
}
.button-send{
  border: none;
  background: white;
  color: $color-primary;
  font-family: 'gotham_medium', Arial;
  text-transform: uppercase;
  position: absolute;
  right: 0;
  padding: 8px 16px;
  margin-top: -50px;
}
