@media (max-width: 1199px) {
  .hidden-for-md{
    display: none;
  }
  .calc-comment,
  .valuta-box.valuta-box-info label{
    font-size: 10px;
  }
  .valuta-box label{
    text-align: left;
    margin-left: 10px;
  }
  a.place-button{
    font-size: 12px;
    width: 82px;
  }
  a.all-place-button{
    width: 94px;
    padding: 14px 4px;
  }
  .valuta-main .amount{
    font-size: 22px;
  }
  .valuta-main h2,
  .valuta-main .black-text{
    font-size: 10px;
  }
  .valuta-box.valuta-box-info span{
    padding: 8px 35px 3px 0px;
    font-size: 10px;
  }
  .slick-main-next{
    left: 650px;
  }
  .valuta-box a{
    font-size: 14px;
  }
  nav a{
    margin-left: 24px;
  }
  .calc-info span{
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .hidden-for-sm{
    display: none;
  }
  .calculator .valuta-main{
    height: 360px;
  }
  .valuta-main{
    margin-top: 15px;
  }
  .slick-main-next{
    left: 730px;
    top: 50px;
  }
  .valuta-box.valuta-box-info span{
    padding: 8px 15px 3px 0px;
    font-size: 10px;
  }
  .calculator{
    margin-top: 110px;
  }
  .girl{
    margin-top: -100px;
  }
}